<template>
  <v-dialog v-model="dialog" class="dialog" max-width="600" persistent>
    <v-card class="card">
      <v-form ref="form" v-model="isValid">
        <h3 class="text-center font-family-raleway-medium mb-5">
          {{$t("edit_campaign_name")}}
        </h3>
        <v-text-field
          class="input-border font-family-raleway-medium"
          :hide-details="statusCode === 400"
          v-debounce:200ms="myDebounceFn"
          v-model.trim="campaignName"
          :class="'border-red'"
          :rules="nameRules"
          :error="statusCode === 400"
          height="60"
          outlined
          required
        >
          <template v-slot:append>
            <div class="d-flex align-center">
              <v-btn
                v-if="campaignName"
                class="py-2 mr-2"
                height="30"
                icon
                @click="clearName"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <span v-if="isCheckingName">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="18"
                ></v-progress-circular>
              </span>
              <div v-else>
                <span v-if="statusCode === 200">
                  <img :src="check" class="mt-1" height="20" width="20" />
                </span>
                <span v-else-if="statusCode === 400">
                  <v-icon color="red"> mdi-close </v-icon>
                </span>
              </div>
            </div>
          </template>
        </v-text-field>
        <span
          class="color-red font-family-raleway-medium ml-3"
          style="font-size: 13px"
          v-if="statusCode === 400"
          >{{ $t("create_campaign_page.name_exist") }}</span
        >
        <v-row class="pb-2">
          <v-col class="text-right mt-2" cols="12">
            <v-btn
              class="mr-5"
              style="box-shadow: none"
              @click="cancelUpdateName"
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn class="button save-btn" height="42" @click="saveCampaignName"
              >{{ $t("save_button") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { campaignService } from "@/services/campaign";
import check from "@/assets/img/check.svg";

export default {
  name: "ChangeCampaignName",
  props: {
    dialog: Boolean,
    defaultName: String,
  },
  data() {
    return {
      statusCode: 0,
      campaignName: "",
      nameRules: [(v) => !!v || this.$t("required_text")],
      isCheckingName: false,
      check,
      isValid: false,
    };
  },
  created() {
    this.campaignName = this.defaultName;
  },
  methods: {
    async myDebounceFn() {
      const customerId = localStorage.getItem("customerId");
      if (this.campaignName && customerId) {
        this.isCheckingName = true;
        try {
          await campaignService.checkCampaignName(
            this.campaignName,
            customerId
          );
          this.statusCode = 200;
          this.isValid = true;
        } catch (e) {
          this.statusCode = e.response?.status;
        }
        this.isCheckingName = false;
      } else {
        this.statusCode = 0;
      }
    },
    saveCampaignName() {
      if (this.isValid) {
        this.$emit("saveCampaignName", this.campaignName);
      }
    },
    cancelUpdateName() {
      console.log(this.defaultName);
      this.$emit("cancelUpdate");
      this.campaignName = this.defaultName;
    },
    clearName() {
      this.campaignName = "";
    },
  },
};
</script>

<style scoped></style>
